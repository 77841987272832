.navbar {
    box-shadow: $header__box-shadow;
}

.navbar-brand {
    text-transform: uppercase;

    span {
        padding: 5px .7rem 6px;
        border: 2px solid $white;
        border-radius: 3px;
    }

    img {
        max-height: 75px;
        max-width: 75px;
        object-fit: contain;
    }
}

.navbar {
    font-family: $font__body;
}

.navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    @include media-breakpoint-up(sm) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.navbar-contact {
    color: theme-color('body-background');
    
    @include media-breakpoint-down(xs) {
        display: none;
    }

    @include media-breakpoint-up(md) {
        order: 1;
        padding: 1rem 0 1rem 1rem;
    }
}

.navbar-expand-md .navbar-nav .download-button a {

    border: 1px solid hsla(0, 0%, 100%, .8);
    color: hsla(0, 0%, 100%, .8);
    background: theme-color('secondary');
    text-align: center;

    @include media-breakpoint-up(md) {
        position: relative;
        padding-right: 1.2rem;
        padding-left: 1.2rem;
        transition: color .25s ease-out, background .25s ease-out, border-color .25s ease-out, padding .25s ease-out;
        transition-duration: .2s;

        &:hover,
        &:focus {
            padding-right: 1.9rem;
            padding-left: .5rem;
            background: theme-color();
            color: hsla(0, 0%, 100%, .75);
            border-color: hsla(0, 0%, 100%, .75);

            &::after {
                opacity: 1;
                right: 10px;
            }
        }
    }
    
    &::after {
        display: inline-block;
        content: 'zum Download';
        margin-left: .3rem;

        @include media-breakpoint-up(md) {
            position: absolute;
            right: 30px;
            top: 50%;
            opacity: 0;
            content: '\2193';
            transform: translateY(-50%);
            vertical-align: middle;
            transition: opacity .25s ease-out, right .25s ease-out;
        }
    }
}
