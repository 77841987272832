body {
    position: relative;
    min-height: 100%;
    padding-top: 56px;
}

.site-content > .content-section {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.content-section-bg {
    background-image: 
        linear-gradient(90deg, rgba(33, 37, 41, .015) 0, rgba(33, 37, 41, .015)),
        linear-gradient(90deg, rgba(33, 37, 41, .015) 0, rgba(33, 37, 41, .015)),
        linear-gradient(to top left, rgba(33, 37, 41, .015) 50%, transparent 0),
        linear-gradient(to top left, rgba(33, 37, 41, .015) 50%, transparent 0);
}

.full-text-widget {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
        .fb_iframe_widget {
            text-align: center;
        }
    }

    @include media-breakpoint-up(md) {
        flex-flow: row wrap;
        justify-content: space-between;

        .section-header {
            flex: 0 0 100%;
        }

        .section-text {
            flex: 0 1 55%;
        }

        .section-widget {
            flex: 0 0 35%;
        }
    }
}
