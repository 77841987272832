.card-deck {
    margin-top: 4rem;
    margin-bottom: 2rem;

    .card {
        margin-bottom: 15px;
    }

    @include media-breakpoint-only(sm) {
        flex-flow: column;
    }

    @include media-breakpoint-up(md) {
        justify-content: space-between;
        
        .card {
            flex: 0 0 45%;
        }
    }

    @include media-breakpoint-up(lg) {
        .card {
            flex: 0 0 30%;
        }
    }
}

.cards {
    background: white;

    header {
        margin-bottom: 2em;
    }

    .card-title {
        text-align: center;
    }

    .card-subtitle {
        color: transparentize(theme-color('text-main'), .6);
        margin-bottom: 15px;
        text-align: center;
    }

    .card-img-top {
        padding: 1.25rem 1.25rem 0;
    }
}
