#gdpr-notice {
  color: #fff;
  background: theme-color("primary");

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 1rem 0;
  opacity: 0.95;
  visibility: visible;
  z-index: 99999999999999;
  -webkit-box-shadow: 1px -2px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -2px 10px rgba(0, 0, 0, 0.4);
  -webkit-transition: opacity 0.3s linear, visibility 0.3s linear;
  transition: opacity 0.3s linear, visibility 0.3s linear;
}

.js #gdpr-notice.hide-notice {
  opacity: 0;
  visibility: hidden;
}

#gdpr-notice b {
  -webkit-padding-after: 2rem;
  padding-block-end: 2rem;
  font-size: 120%;
  letter-spacing: 0.002rem;
}

#gdpr-notice a {
  color: inherit !important;
  text-decoration: underline;
}

#gdpr-notice p {
  max-width: 1280px;
  padding: 0 2rem 1rem;
  line-height: 1.6;
  letter-spacing: 0.002rem;
}

#gdpr-notice button {
  position: relative;
  display: inline-block;
  line-height: 1.5;
  padding: 0.75rem 2.1875rem;
  background: rgba(0, 0, 0, 0.1);
  border: 3px solid currentColor;
  text-shadow: none;
  color: inherit;
  border-radius: 3px;
  letter-spacing: 0.1rem;
  -webkit-transition: background 0.3s linear;
  transition: background 0.3s linear;
}

#gdpr-notice button:focus,
#gdpr-notice button:hover {
  background: rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 768px) {
  #gdpr-notice p {
    padding: 0 3rem 1rem;
  }
}
