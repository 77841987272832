.site-footer {
    padding: 1.25rem 0 3rem;
    font-size: .875rem;
    text-align: center;
    background: #f7f7f7;
}

.site-info {
    margin-bottom: 1rem;
    font-size: 0;

    span {
        display: block;
        @include font-size(14);
        
        @include media-breakpoint-up(md) {
                display: inline;
        }
    }

    @include media-breakpoint-down(sm) {
        .sep {
            display: none;
        }
    }
}
