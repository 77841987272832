/**
 * The lightbox adds padding to body and navbar whenever an image opens, which shifts the layout to the side.
 * ...need a new lightbox...
 */
body.modal-open {
    padding-right: 0 !important;
    
    .navbar {
        padding: .5rem 1rem !important;
    }
}
