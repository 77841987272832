.banner {
    flex: 0 1 56vh;
    background-color: theme-color('carousel-background');

    img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
}
