.full-text-list ul {
    padding: 0;
    text-align: center;
    list-style-type: none;

    li {
        position: relative;
        padding: 1em 0 1.5em;
        margin-bottom: .5em;

        &::after {
            display: block;
            position: absolute;
            content: '';
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            background: linear-gradient(to right, transparent, theme-color('accent'), transparent);
        }
    }
}
