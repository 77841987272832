.hide {
    display: none !important;
}

.hide-visually {
    visibility: hidden;
    opacity: 0;
}

.show-visually {
    visibility: visible;
    opacity: 1;
}

.no-js .hide-if-no-js {
    display: none !important;
}