.full-text {
    p {
        line-height: 1.67;
        margin-top: 0.5em;
        margin-bottom: 1em;
        letter-spacing: .005em;
    }

    address,
    blockquote,
    dl,
    p,
    ul,
    ol,
    figure {
        max-width: 30rem;
        margin: 0 auto 2rem;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        max-width: 50rem;
        margin: 3rem auto 2rem;
    }
}


.text-line {
    @include media-breakpoint-up(md) {
        position: relative;
        margin-top: 4rem;
        padding-right: 1rem;
        border-top-right-radius: 1rem;
        border-top: 5px solid theme-color('accent');
        border-right: 5px solid theme-color('accent');

        h2,
        h3:first-child {
            position: relative;
            display: inline-block;
            padding-right: 1rem;
            margin: 0 auto 2rem;
            background: theme-color('body-background');
            transform: translateY(-50%);
        }
    }
}
