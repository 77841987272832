.gallery {
    &__image {
        margin-bottom: 1rem; 
          
        @include media-breakpoint-up(sm) {
            display: flex;
            height: 200px;     
        }

        @include media-breakpoint-up(xl) {
            height: 250px;
        }

        img {
            min-width: 100%;
            min-height: 100%;
            object-fit: cover;
        }
    }
}
